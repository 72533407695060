var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"justify-center",attrs:{"id":"app"}},[_c('v-container',{style:(_vm.containerStyle),attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-img',{staticClass:"justify-center",attrs:{"max-height":"500","max-width":"300","src":require("../assets/Logo-Radio-Trunk_blanco.png")}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","align":"center","justify":"center"}},[_c('v-text-field',{staticClass:"justify-center",attrs:{"label":"username","dark":"","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$store.dispatch('auth/login', {
              username: _vm.localemail,
              pass: _vm.password,
            })}},model:{value:(_vm.localemail),callback:function ($$v) {_vm.localemail=$$v},expression:"localemail"}}),_c('v-text-field',{staticClass:"justify-center",attrs:{"label":"password","type":"password","rules":_vm.passwordrules,"dark":"","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$store.dispatch('auth/login', {
              username: _vm.localemail,
              pass: _vm.password,
            })}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticStyle:{"margin":"0px auto"},attrs:{"outlined":"","rounded":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('auth/login', {
              username: _vm.localemail,
              pass: _vm.password,
            })}}},[_vm._v(" Login ")]),_c('br'),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","color":"white","href":"https://play.google.com/store/apps/details?id=com.radiotrunk.tickets"}},[_c('v-icon',[_vm._v("mdi-android")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","color":"white","href":"https://apps.apple.com/mx/app/ticketsradiotrunk/id1591444714"}},[_c('v-icon',[_vm._v("mdi-apple")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","color":"white","href":"https://radiotrunk.com/ManualTickets.pdf"}},[_c('v-icon',[_vm._v("mdi-book")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }