<template>
  <div id="app" class="justify-center">
    <v-container fluid :style="containerStyle">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="7">
          <v-img
            class="justify-center"
            max-height="500"
            max-width="300"
            src="../assets/Logo-Radio-Trunk_blanco.png"
          />
        </v-col>
        <v-col cols="12" sm="4" align="center" justify="center">
          <v-text-field
            v-model="localemail"
            @keyup.enter="
              $store.dispatch('auth/login', {
                username: localemail,
                pass: password,
              })
            "
            class="justify-center"
            label="username"
            dark
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            @keyup.enter="
              $store.dispatch('auth/login', {
                username: localemail,
                pass: password,
              })
            "
            class="justify-center"
            label="password"
            type="password"
            :rules="passwordrules"
            dark
            required
          ></v-text-field>
          <v-btn
          style="margin: 0px auto"
            @click.prevent="
              $store.dispatch('auth/login', {
                username: localemail,
                pass: password,
              })
            "
            outlined
            rounded
            dark
          >
            Login
          </v-btn>
          <br>
          <v-btn class="ma-2" outlined fab color="white" href="https://play.google.com/store/apps/details?id=com.radiotrunk.tickets">
            <v-icon>mdi-android</v-icon>
          </v-btn>
          <v-btn class="ma-2" outlined fab color="white" href="https://apps.apple.com/mx/app/ticketsradiotrunk/id1591444714">
            <v-icon>mdi-apple</v-icon>
          </v-btn>
          <v-btn class="ma-2" outlined fab color="white"  href="https://radiotrunk.com/ManualTickets.pdf">
            <v-icon>mdi-book</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      localemail: "",
      password: "",
      containerStyle: "",
      passwordrules: [(v) => !!v || "password is required"],
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    reziseBackground() {
      let padding_top;
      if (window.innerHeight < 900) {
        padding_top = window.innerHeight / 4;
      } else {
        padding_top = window.innerHeight / 2.5;
      }
      this.containerStyle =
        "padding-top: " +
        padding_top +
        "px; height: " +
        window.innerHeight +
        "px; width: " +
        window.innerWidth +
        "px;";
    },
  },
  components: {},
  created() {
    this.reziseBackground();
  },
};
</script>

<style scoped>
#app,
#color {
  background: rgb(0, 93, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 93, 255, 1) 0%,
    rgba(21, 40, 71, 1) 100%
  );
}
</style>